import * as React from 'react';

import Box from '@lce/slice_v2/Layout/Box';

const TestMenuItem: React.FC = props => (
  <Box sx={{
    backgroundColor: 'primary',
    minHeight: '250px',
    mb: '24px',  
  }}>
    <Box>SOME CUSTOM MENU ITEM COMPONENT</Box>
    <Box>{JSON.stringify(props)}</Box>
  </Box>
);

export default TestMenuItem;
