import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';

export interface IPriceProps {
  priceText: string;
  price: string;
  subscript: string;
}

const Price: React.FC<IPriceProps> = ({ priceText, price, subscript }) => (
  <Box sx={{ mt: '8px' }}>
    <Box sx={{
      fontFamily: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      lineHeight: '20px',
      fontSize: '20px',
    }}>{priceText}</Box>
    <Box sx={{
      color: 'white',
      fontFamily: 'primary',
      lineHeight: '24px',
      fontSize: '32px',
    }}>
      {price} {subscript &&
        <Box as='sub' sx={{ verticalAlign: 'baseline', fontSize: '16px' }}>{subscript}</Box>}
    </Box>
  </Box>
);

export default Price;
