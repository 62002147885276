import { SxStyleProp } from 'theme-ui';

export const SimpleBanner: SxStyleProp = {
  backgroundColor: 'primary',
  mb: '24px',
  p: '12px',
  position: 'relative',
  overflow: 'hidden',
  flexDirection: 'row',
};

export const Headline: SxStyleProp = {
  color: 'white',
  fontFamily: 'primary',
  zIndex: 1,
  mt: [ '100px', '52px', '0' ],
  fontSize: [ 24, 44 ],
  fontWeight: 'bold',
  lineHeight: '0.8em',
};

export const TitleScript: SxStyleProp = {
  color: 'black',
  fontWeight: 'normal',
  fontSize: '0.75em',
  mb: '0',
};

export const Image: SxStyleProp = {
  width: '100%',
  height: 'auto',
};
