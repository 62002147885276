import { SxStyleProp } from 'theme-ui';

export const HeaderLayout: SxStyleProp = {
  textAlign: 'center',
  backgroundColor: '#bcbec0',
  mb: 12,
  p: '8px 0',
};

export const textHeader: SxStyleProp = {
  fontWeight: 'bold',
  mb: 0,
  color: 'white',
  fontSize: 14,
};

export const legalText: SxStyleProp = {
  fontFamily: 'tertiary',
};
