import { SxStyleProp } from 'theme-ui';

export const MenuItem: SxStyleProp = {
  backgroundColor: 'primary',
  flexDirection: ['column-reverse', 'row'],
  overflow: 'hidden',
  position: 'relative',
  height: ['inherit', '264px'],
  marginBottom: '24px',
};

export const ItemContent: SxStyleProp = {
  alignSelf: 'center',
  p: ['16px', '0 4px 0 0'],
  width: ['100%', '37.0915%'],
};

export const ItemImage: SxStyleProp = {
  width: ['100%', '62.9084%'],
  alignSelf: 'flex-end',
  minHeight: '1px',
};

export const Image: SxStyleProp = {
  width: '100%',
  height: 'auto',
  minHeight: '1px',
  m: '0',
  verticalAlign: 'bottom',
};

export const PreHeader: SxStyleProp = {
  fontFamily: 'primary',
  textTransform: 'uppercase',
  lineHeight: '20px',
  fontWeight: 'bold',
  fontSize: '24px',
};

export const ItemName: SxStyleProp = {
  fontFamily: 'primary',
  color: 'white',
  textTransform: 'uppercase',
  lineHeight: '28px',
  fontWeight: 'bold',
  fontSize: '32px',
  mb: '0',
};

export const Description: SxStyleProp = {
  fontFamily: 'primary',
  textTransform: 'uppercase',
  marginTop: '8px',
  fontWeight: 'bold',
  lineHeight: '20px',
  fontSize: '16px',
};

export const CornerIcon: SxStyleProp = {
  position: 'absolute',
  left: '12px',
  bottom: [ '12px', 'auto' ],
  top: [ 'auto', '12px' ],
  m: '0',
  zIndex: '1',
};
