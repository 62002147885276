import * as React from 'react';
import Text from '@lce/slice_v2/Elements/Text';
import Box from '@lce/slice_v2/Layout/Box';

import SimpleBanner, { ISimpleBannerProps } from '../../Patterns/Banners/SimpleBanner';

import * as Styled from './Menu.style';
import MenuItemList, { IMenuItemListProps } from './components/MenuItemList';

export interface IMenuProps extends IMenuItemListProps {
  menu: IMenuPageProps;
}

export interface IMenuPageProps {
  simpleBanner?: ISimpleBannerProps;
  textBar?: string;
  legalText?: any;
}

const Menu: React.FC<IMenuProps> = ({ menu: { simpleBanner, textBar, legalText }, activeMenuItems }) => (
  <>
    {textBar && (
      <Box sx={Styled.HeaderLayout}>
        <Text as='h1' sx={Styled.textHeader}>{textBar}</Text>
      </Box>
    )}
    {simpleBanner && <SimpleBanner {...simpleBanner}/>}
    <MenuItemList activeMenuItems={activeMenuItems} />
    {legalText && <Box dangerouslySetInnerHTML={{ __html: legalText }} sx={Styled.legalText}/>}
  </>
);

export default Menu;
