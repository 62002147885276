import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Flex from '@lce/slice_v2/Layout/Flex';
import Heading from '@lce/slice_v2/Elements/Heading';
import Image from '@lce/slice_v2/Elements/Image';
import { Direction } from '@lce/intl-types/src/IDirection';

import ComponentListRenderer from '../../../Modules/ComponentListRenderer';
import Script from '../../../Elements/Script';
import * as Styled from './SimpleBanner.style';

export interface ISimpleBannerProps {
  script?: string;
  title?: string[];
  image?: string;
  direction: Direction;
}

const SimpleBanner: React.FC<ISimpleBannerProps> = ({ script, title, image, direction }) => (
  <Flex sx={Styled.SimpleBanner}>
    <Heading as='h1' sx={Styled.Headline}>
      <Script data-testid="SimpleBannerTitle" sx={Styled.TitleScript}>{script}</Script>
      <ComponentListRenderer listText={title} />
    </Heading>
    {image && (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: direction === 'rtl' ? '0' : 'auto',
          right: direction === 'rtl' ? 'auto' : '0',
        }}
      >
        <Image alt="" src={image} sx={Styled.Image} />
      </Box>
    )}
  </Flex>
);

export default SimpleBanner;
