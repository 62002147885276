import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';

import MenuColumnLoader, { IActiveMenuItemProps } from './components/MenuColumnLoader';

export interface IMenuItemListProps {
  activeMenuItems: IActiveMenuItemProps[];
}

const MenuItemList: React.FC<IMenuItemListProps> = ({ activeMenuItems }) => (
  <Flex
    sx={{
      justifyContent: 'space-between',
      flexDirection: ['column', 'row'],
      width: '100%',
      minHeight: '624px',
    }}
  >
    <MenuColumnLoader column="Left" list={activeMenuItems} />
    <MenuColumnLoader column="Right" list={activeMenuItems} />
  </Flex>
);

export default MenuItemList;
