import * as React from 'react';
import { map as _map, isEmpty as _isEmpty } from 'lodash';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Image from '@lce/slice_v2/Elements/Image';
import Text from '@lce/slice_v2/Elements/Text';
import Heading from '@lce/slice_v2/Elements/Heading';

import checkCountryLocale from '@lce/intl-util/src/CheckCountryLocale';
import ComponentListRenderer from '../../../../../../Modules/ComponentListRenderer';
import Price, { IPriceProps } from './components/Price';
import * as Styled from './MenuItem.style';

export interface IMenuItemProps {
  preHeader?: string[];
  itemName: string[];
  image: string;
  price: IPriceProps[];
  description?: string[];
  icon?: string;
}

const MenuItem: React.FC<IMenuItemProps> = ({ preHeader, description, icon, itemName, image, price, ...props }) => {
  const isRussiaSite = checkCountryLocale('ru-ru');
  return(
    <Flex {...props} sx={Styled.MenuItem}>
      {icon && <Image alt="" src={icon} sx={Styled.CornerIcon} />}
      <Box sx={Styled.ItemImage}>
        <Image alt="" src={image} sx={Styled.Image}/>
      </Box>
      <Box sx={Styled.ItemContent}>
        {!_isEmpty(preHeader) && 
            <Text sx={ isRussiaSite ? { ...Styled.PreHeader, fontSize: '16px' } : Styled.PreHeader }>
              <ComponentListRenderer listText={preHeader} />
            </Text> 
        }
        <Heading as='h2' sx={ isRussiaSite ? { ...Styled.ItemName, fontSize: '26px' } : Styled.ItemName }>
          <ComponentListRenderer listText={itemName} />
        </Heading>
        {_map(price, (priceObject, index) => (
          <Price key={`${priceObject.price}-${index}`} {...priceObject} />
        ))}
        {!_isEmpty(description) && <Text sx={Styled.Description}>
          <ComponentListRenderer listText={description} />
        </Text> 
        }
      </Box>
    </Flex>
  );
};

export default MenuItem;
