import * as React from 'react';
import { filter as _filter, map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';

import CustomMenuItem from '../../../../CustomMenuItems/useCustomMenuItems';
import MenuItem, { IMenuItemProps } from '../MenuItem';

interface IMenuColumnLoader {
  list: IActiveMenuItemProps[];
  column: string;
}

export interface IActiveMenuItemProps {
  isRight: boolean;
  position?: number;
  customMenuItemComponent?: string;
  menuItem?: IMenuItemProps;
  id: string;
}

const MenuColumnLoader: React.FC<IMenuColumnLoader> = ({ list, column }) => {
  const filteredList = _filter(list, menuItem => menuItem.isRight === (column === 'Right'));

  const ItemList = _map(filteredList, menuItem => {
    const ValidCustomComponent =
      menuItem.customMenuItemComponent &&
      CustomMenuItem[menuItem.customMenuItemComponent] &&
      CustomMenuItem[menuItem.customMenuItemComponent];
    const menuComponent = ValidCustomComponent ? (
      <ValidCustomComponent data-testid="CustomMenuItem" key={menuItem.id} />
    ) : (
      menuItem.menuItem && menuItem.menuItem.itemName && (
        <MenuItem
          key={menuItem.id}
          {...menuItem.menuItem}
        />
      )
    );

    return menuComponent;
  });
  return (
    <Box
      data-testid={`Column-${column === 'Right' ? 'Right' : 'Left'}`}
      sx={{
        width: ['100%', 'calc(50% - 12px)'],
        minHeight: '1px',
      }}
    >
      {ItemList}
    </Box>
  );
};

export default MenuColumnLoader;
