/* eslint-disable max-lines-per-function */
import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, isEmpty as _isEmpty, sortBy as _sortBy, union as _union } from 'lodash';
import { Direction } from '@lce/intl-types/src/IDirection';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import removeLine from '@lce/intl-util/src/removeLine';
import { IMenuProps } from '@lce/intl-ui/src/PageComponents/Menu';
import { IMenuPageProps } from '@lce/intl-ui/src/PageComponents/Menu/Menu';
import franchiseeFilter from '@lce/intl-util/src/FranchiseeFilter';
import { IMenuItemListProps } from '@lce/intl-ui/src/PageComponents/Menu/components/MenuItemList';
import { IActiveMenuItemProps } from '@lce/intl-ui/src/PageComponents/Menu/components/MenuItemList/components/MenuColumnLoader';
import franchiseeDirection from '@lce/intl-util/src/FranchiseeDirection';

import { ISiteConfigProps } from '../Config/useSiteConfig';
import { useSelectedFranchiseeId } from '../FranchiseeId/hooks/useSelectedFranchiseeId';
import useFranchisees from '../FranchiseeId/hooks/useFranchisees';

interface IDatoUseMenu extends ISiteConfigProps {
  menu: IDatoMenuNodes;
  activeMenuItems: IDatoActiveMenuItemNodes;
  franchiseeMenu: IDatoFranchiseeMenuNodes;
}

interface IDatoFranchiseeMenuNodes {
  nodes: IDatoFranchiseeMenu[];
}

interface IDatoFranchiseeMenu {
  locale: string;
  menuName: string;
  textBar: string;
  bannerScript?: string;
  bannerTitle?: ILineObject[];
  bannerImage?: {
    url: string;
  };
  legalText: string;
  leftColumn: IMenuItem[];
  rightColumn: IMenuItem[];
  franchisee: {
    name: string;
    originalId: string;
  };
}

interface IDatoMenuNodes {
  nodes: IDatoBannerMenu[];
}

interface IDatoActiveMenuItemNodes {
  nodes: IDatoActiveMenuItem[];
}

interface IDatoBannerMenu {
  locale: string;
  textBar: string;
  bannerScript?: string;
  bannerTitle?: ILineObject[];
  bannerImage?: IDatoImage;
  legalText: string;
}

interface IPrice {
  priceText: string;
  price: string;
  subscript: string;
}

interface IDatoActiveMenuItem {
  id: string;
  nonDisplayTitle: string;
  isRight: boolean;
  menuItem?: IMenuItem;
}

interface IMenuItem {
  locale: string;
  title: string;
  preHeader: ILineObject[];
  name: ILineObject[];
  image: {
    alt?: string;
    url: string;
  };
  price: IPrice[];
  description: ILineObject[];
  icon: IDatoImage;
  id: string;
}

interface IDatoMenu extends IMenuItemListProps {
  menu: IMenuPageProps;
}

// eslint-disable-next-line max-lines-per-function
const useMenu = (locale: string) => {
  const data: IDatoUseMenu = useStaticQuery(graphql`
    query UseMenuMsQuery {
      franchiseeMenu: allDatoCmsFranchiseeMenuItem {
        nodes {
          locale
          menuName
          textBar
          bannerScript
          bannerTitle {
            line
          }
          bannerImage {
            url
          }
          legalText
          leftColumn {
            title
            preHeader {
              line
            }
            name {
              line
            }
            image {
              url
            }
            price {
              priceText
              price
              subscript
            }
            description {
              line
            }
            icon {
              url
            }
            id
          }
          rightColumn {
            title
            preHeader {
              line
            }
            name {
              line
            }
            image {
              url
            }
            price {
              priceText
              price
              subscript
            }
            description {
              line
            }
            icon {
              url
            }
            id
          }
          franchisee {
            name
            originalId
          }    
        }
      }
    }
  `);
  
  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);
  const { franchisees } = useFranchisees(locale);

  const direction: Direction = franchiseeDirection(franchisees, SelectedFranchiseeId);
  
  const filteredMenuByFranchisee = franchiseeFilter<IDatoFranchiseeMenu>(data?.franchiseeMenu?.nodes, SelectedFranchiseeId, locale);

  const newActiveMenuItem = (menuItem: IMenuItem, isRight: boolean, key: number): IActiveMenuItemProps => {
    const newMenuItem = menuItem &&
    !_isEmpty(menuItem) ? {
        ...menuItem,
        description: removeLine(menuItem.description),
        icon: menuItem?.icon?.url,
        image: menuItem?.image?.url,
        itemName: removeLine(menuItem?.name),
        preHeader: removeLine(menuItem?.preHeader),
      } : undefined;
      
    return {
      id: menuItem.id,
      isRight,
      position: key,
      menuItem: newMenuItem,
    };
  };

  const leftColumn: IActiveMenuItemProps[] = _map(filteredMenuByFranchisee?.leftColumn, (menuItem, index) => newActiveMenuItem(menuItem, false, index));
  const rightColumn: IActiveMenuItemProps[] = _map(filteredMenuByFranchisee?.rightColumn, (menuItem, index) => newActiveMenuItem(menuItem, true, index));

  const cleanedActiveMenuItems = _union<IActiveMenuItemProps>(leftColumn, rightColumn);

  const simpleBanner = {
    script: filteredMenuByFranchisee?.bannerScript,
    title: removeLine(filteredMenuByFranchisee?.bannerTitle),
    image: filteredMenuByFranchisee?.bannerImage?.url,
  };

  delete filteredMenuByFranchisee?.bannerImage;
  delete filteredMenuByFranchisee?.bannerTitle;
  delete filteredMenuByFranchisee?.bannerScript;

  const sortedActiveMenuItems = _sortBy<IActiveMenuItemProps>(cleanedActiveMenuItems, (activeMenuItems) => activeMenuItems?.position);

  const menuPage: IMenuProps = {
    menu: {
      ...filteredMenuByFranchisee,
      simpleBanner: simpleBanner?.title && simpleBanner?.image ? { ...simpleBanner, direction } : undefined,
    },
    activeMenuItems: {
      ...sortedActiveMenuItems,
    },
  };

  return menuPage;

};

export default useMenu;
