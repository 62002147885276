import * as React from 'react';
//Import custom banners here and add them to the Banners object.
import TestMenuItem from './TestMenuItem';

export interface IObjectIndexer<T> {
  [key: string]: T;
}

const CustomMenuItems: IObjectIndexer<React.FC<any>> = {
  TestMenuItem,
};

export default CustomMenuItems;
